import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";

export const ImagesCarousel = styled("div")`
  ${({ theme }): SerializedStyles => css`
    width: 100%;
    .slick-prev {
      left: 0 !important;
    }
    .slick-next {
      right: 0 !important;
    }
    .slick-dots {
      bottom: ${theme.spacing(-4)};
      li {
        button::before {
          font-size: 12px;
          color: ${theme.palette.primary.main};
          opacity: 0.1;
          &:hover {
            color: ${theme.palette.primary.main};
            opacity: 0.1;
          }
        }
      }
      .slick-active {
        button::before {
          color: ${theme.palette.primary.light};
          opacity: 1;
        }
      }
    }
  `}
`;

export const ImageWrapper = styled("div")`
  ${({ theme }): SerializedStyles => css`
    margin: ${theme.spacing(0, 2.5)};
    ${theme.breakpoints.up("md")} {
      margin: ${theme.spacing(0, 3)};
    }
  `}
`;
