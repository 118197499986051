import React from "react";
import { ImageDataLike } from "gatsby-plugin-image";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "../../arrows";
import { Image } from "../../image";
import { ImagesCarousel, ImageWrapper } from "./styles";

export type PpMediaImagesBlock = {
  images: Array<{
    image: {
      alt: string;
      urlSharp: ImageDataLike;
    };
  }>;
};

export function MediaImagesBlock({
  images,
  ...rest
}: PpMediaImagesBlock): React.ReactElement | null {
  if (images.length === 0) return null;
  if (images.length === 1)
    return (
      <Image
        fullWidth
        alt={images[0].image.alt}
        src={images[0].image.urlSharp}
      />
    );

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    nextArrow: <NextArrow isInverted />,
    pauseOnHover: false,
    prevArrow: <PrevArrow isInverted />,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    swipe: true,
  };
  return (
    <ImagesCarousel {...rest}>
      <div>
        <Slider {...settings}>
          {images.map(({ image }) => (
            <div key={image.alt}>
              <ImageWrapper>
                <Image fullWidth alt={image.alt} src={image.urlSharp} />
              </ImageWrapper>
            </div>
          ))}
        </Slider>
      </div>
    </ImagesCarousel>
  );
}
